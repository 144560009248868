import { motion } from "framer-motion";
import React from "react";
import styles from "./Loading.module.css";

const DURATION = 3.4;

// Adapted from https://codepen.io/aaroniker/pen/zYOewEP
const BookAnimation = () => {
  const numPages = 18;
  const pages = React.useMemo<number[]>(() => {
    const p = [];
    for (let i = 0; i < numPages; i++) {
      p[i] = i;
    }
    return p;
  }, []);

  return (
    <div className={`${styles.book} mb-8`}>
      <motion.div
        className={`${styles.inner}`}
        initial={{
          rotateZ: -90,
          originX: "2px",
          originY: "2px",
        }}
        animate={{
          rotateZ: [-90, 0, 0, null, 90, 90, null, 0, 0, -90] as number[],
          originX: [
            "2px",
            "2px",
            "2px",
            "30px",
            "30px",
            "30px",
            "30px",
            "2px",
            "2px",
            "2px",
          ] as string[],
        }}
        transition={{
          duration: DURATION,
          repeat: Infinity,
          times: [0.04, 0.1, 0.4, 0.4001, 0.46, 0.54, 0.5999, 0.6, 0.9, 0.96],
        }}
      >
        <motion.div
          initial={{
            rotateZ: 90,
            transformOrigin: "58px 2px",
          }}
          animate={{
            rotateZ: [90, 0, 0, 90, 90, 0, 0, 90],
          }}
          transition={{
            duration: DURATION,
            repeat: Infinity,
            times: [0.04, 0.1, 0.4, 0.46, 0.54, 0.6, 0.9, 0.96],
          }}
          className={`${styles.left} ${styles.side}`}
        ></motion.div>
        <div className={`${styles.middle}`}></div>
        <motion.div
          className={`${styles.right} ${styles.side}`}
          initial={{ transformOrigin: "2px 2px", rotateZ: -90 }}
          animate={{
            rotateZ: [-90, 0, 0, -90, -90, 0, 0, -90],
          }}
          transition={{
            duration: DURATION,
            repeat: Infinity,
            times: [0.04, 0.1, 0.4, 0.46, 0.54, 0.6, 0.9, 0.96],
          }}
        ></motion.div>
      </motion.div>
      <ul className={`${styles.pages}`}>
        {pages.map(p => {
          const delay = (p * 1.86) / 100;
          const delayAfter = (p * 1.74) / 100;

          return (
            <motion.li
              className={`${styles.page}`}
              key={p}
              initial={{
                transform: "rotateZ(0deg) translateX(-18px)",
                transformOrigin: "100% 2px",
              }}
              animate={{
                transform: [
                  "rotateZ(0deg) translateX(-18px)",
                  "rotateZ(180deg) translateX(-18px)",
                  "rotateZ(180deg) translateX(-18px)",
                  "rotateZ(0deg) translateX(-18px)",
                ],
              }}
              transition={{
                duration: DURATION,
                repeat: Infinity,
                times: [
                  0.04 + delay,
                  0.13 + delayAfter,
                  0.54 + delay,
                  0.63 + delayAfter,
                ],
              }}
            />
          );
        })}
      </ul>
    </div>
  );
};

export const Loading = () => {
  return (
    <div
      className={`flex flex-col items-center justify-center w-screen h-screen bg-brand box-border absolute z-50`}
    >
      <BookAnimation />
      <div className="mt-8 text-light">Loading...</div>
    </div>
  );
};
