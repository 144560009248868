/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import "../pages/index.css";
import { LocationContext } from "../providers/Location";
import { Header } from "./Header";
import styles from "./Layout.module.css";
import { Loading } from "./Loading";
import { Main } from "./Main";
import { Ribbon } from "./Ribbon";

const LOADER_DURATION = 1000;

export interface LayoutProps {
  children: React.ReactNode;
  pageContext: {
    showProfilePicture: boolean;
  };
  location: Location;
}

export const Layout = ({ children, pageContext, location }: LayoutProps) => {
  const showProfilePicture = pageContext?.showProfilePicture;
  const [isLoading, setIsLoading] = React.useState(true);
  setTimeout(() => setIsLoading(false), LOADER_DURATION);

  return (
    <>
      {isLoading && <Loading />}
      <LocationContext.Provider value={location}>
        <div
          className={`grid sm:grid-cols-1 grid-rows-app-layout min-h-screen box-border justify-center justify-items-start ${styles.container}`}
        >
          <Header className={`${styles.header}`} />

          <div className="flex w-screen">
            <Ribbon />
            <Main showProfilePicture={showProfilePicture}>{children}</Main>
          </div>

          <footer
            className={`fixed bottom-0 w-screen leading-10 h-10 bg-primary text-xxs flex justify-center border-t text-muted ${styles.footer} z-10`}
          >
            © Saba Sulaiman 2017 - {new Date().getFullYear()}
          </footer>
        </div>
      </LocationContext.Provider>
    </>
  );
};

export default Layout;
