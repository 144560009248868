import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { LocationContext } from "../providers/Location";
import { Image, ImageType } from "./Image";

export interface Props {
  showProfilePicture: boolean;
  children: React.ReactNode;
}

export const Main = ({ showProfilePicture, children }: Props) => {
  const location = React.useContext(LocationContext);

  return (
    <AnimatePresence key={location?.pathname || "main"} exitBeforeEnter>
      <motion.main
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        className={classnames(
          { "md:grid-cols-main-layout": showProfilePicture },
          `grid grid-cols-1 w-full px-6 py-6 md:px-8 md:py-8 lg:px-12 mb-10 gap-0 md:gap-8 justify-items-center justify-center`
        )}
      >
        {showProfilePicture && (
          <div className={`sm:w-2/4 md:min-w-full mb-8 sm:min-w-32`}>
            <Image image={ImageType.headshot} className="rounded-xl" />
          </div>
        )}
        {children}
      </motion.main>
    </AnimatePresence>
  );
};
