/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "fontsource-league-gothic";
import "typeface-quicksand";
import "typeface-special-elite";
import "./src/global.css";
