import classnames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { Ribbon } from "../Ribbon";
import { HamburgerMenu } from "./HamburgerMenu";
import styles from "./Header.module.css";
import { NavBar } from "./NavBar";
import { MenuItem } from "./types";

export const data: MenuItem[] = [
  {
    title: "About Me",
    children: [
      {
        title: "Bio",
        link: "/about",
      },
      { title: "Genres I Represent", link: "/representation" },
    ],
  },
  { title: "Interviews & Posts", link: "/media" },
  { title: "Conferences", link: "/conferences" },
  { title: "Pitch Me", link: "/pitch" },
  { title: "Contact", link: "/contact" },
];

export interface HeaderProps {
  className?: string;
}

export const Header = ({ className }: HeaderProps) => (
  <div className="flex w-screen border-ribbon sm:border-b-40 lg:border-b-0">
    <Ribbon className={`${styles.ribbon} sm:border-b-40 `} />

    <header
      className={classnames(
        "bg-primary flex flex-col flex-1",
        "z-10",
        className
      )}
    >
      <HamburgerMenu className="lg:hidden" data={data} />

      <div className="w-full px-4 py-4 my-0 sm-only:pt-3">
        <h1>
          <Link
            to="/"
            className="leading-none text-center uppercase whitespace-pre font-title text-brand text-title md:text-title_md lg:text-title_lg"
            style={{
              textShadow:
                "1px 1px 0px var(--color-text-shadow-primary), 2px 2px 0px var(--color-text-shadow-secondary)",
            }}
          >
            <div className="px-8 whitespace-normal ">
              S<small>aba </small> S<small>ulaiman</small>
            </div>
          </Link>
        </h1>
        <h2 className="mx-auto text-center text-subtitle md:text-subtitle_md lg:text-subtitle_lg">
          Senior Agent, Talcott Notch Literary Services
        </h2>
      </div>

      <NavBar className="sm-only:hidden md-only:hidden" data={data} />
    </header>
  </div>
);

export default Header;
