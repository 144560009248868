import classnames from "classnames";
import { Link as GatsbyLink } from "gatsby";
import { ChevronDown } from "heroicons-react";
import React from "react";
import { LocationContext } from "../../providers/Location";
import { Dropdown } from "../Dropdown";
import { MenuItem } from "./types";

interface MenuProps {
  className?: string;
  data: MenuItem[];
}

interface LinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  activeClassName?: string;
}

const Link = ({ to, children, className, activeClassName }: LinkProps) => {
  return (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      className={classnames(className)}
    >
      {children}
    </GatsbyLink>
  );
};

export const NavBar = ({ className, data }: MenuProps) => {
  const location = React.useContext(LocationContext);
  const anyChildIsActive = React.useCallback(
    (item: MenuItem) => {
      return item.children?.some(i => i.link === location?.pathname);
    },
    [location]
  );

  return (
    <div
      className={classnames(
        className,
        "flex justify-center bg-brand h-10 content-center text-light items-center pt-1"
      )}
    >
      {data.map(item => (
        <div
          key={item.title}
          className="flex items-center h-full mr-4 tracking-normal font-display"
        >
          {item.link ? (
            <Link
              activeClassName="nav-bar-item--selected"
              className="nav-bar-item hover:nav-bar-item"
              to={item.link}
            >
              {item.title}{" "}
            </Link>
          ) : (
            <Dropdown
              trigger={
                <div
                  role="button"
                  className={classnames(
                    "flex items-center h-full select-none nav-bar-item hover:nav-bar-item",
                    { "nav-bar-item--selected": anyChildIsActive(item) }
                  )}
                >
                  <div className="mr-1">{item.title}</div>
                  <div>
                    <ChevronDown size={20} />
                  </div>
                </div>
              }
            >
              <div className="flex flex-col py-4 border rounded-sm shadow-lg font-display bg-primary border-dark">
                {item.children?.map(child => (
                  <Link
                    key={child.title}
                    activeClassName="nav-bar-sub-menu-item--selected"
                    className="py-2 nav-bar-sub-menu-item hover:nav-bar-sub-menu-item hover:bg-brand hover:text-light"
                    to={child.link!}
                  >
                    {child.title}
                  </Link>
                ))}
              </div>
            </Dropdown>
          )}
        </div>
      ))}
    </div>
  );
};
