import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Link as GatsbyLink } from "gatsby";
import { Menu as MenuIcon } from "heroicons-react";
import React from "react";
import { PortalFunctionParams, PortalWithState } from "react-portal";
import styles from "./HamburgerMenu.module.css";
import { MenuItem } from "./types";

interface MenuProps {
  className?: string;
  data: MenuItem[];
  onOpen?: () => void;
}

export interface MenuItemProps {
  closeMenu: () => void;
  className?: string;
  data: MenuItem[];
}

interface LinkProps {
  to: string;
  children: React.ReactNode;
  closeMenu?: () => void;
}

const Link = ({ to, children, closeMenu }: LinkProps) => {
  return (
    <GatsbyLink
      to={to}
      onClick={closeMenu}
      activeClassName={styles.activeLink}
      className="hover:font-bold"
    >
      {children}
    </GatsbyLink>
  );
};

const MenuItems = ({ closeMenu, className, data }: MenuItemProps) => {
  return (
    <ul
      className={classnames(
        "flex-1 bg-primary p-4 font-display uppercase text-sm tracking-tight",
        styles.menuItems,
        className
      )}
    >
      {data.map(item => {
        if (item.link) {
          return (
            <li key={item.title}>
              <Link closeMenu={closeMenu} to={item.link}>
                {item.title}
              </Link>
            </li>
          );
        }
        if (item.children && item.children?.length > 0) {
          return [
            <li key={`item-${item.title}`} className="text-muted">
              {item.title}
            </li>,
            <ul key={`sub-menu-item-${item.title}`} className="px-4">
              {item.children.map(child => (
                <li key={child.title}>
                  <Link closeMenu={closeMenu} to={child.link!}>
                    {child.title}
                  </Link>
                </li>
              ))}
            </ul>,
          ];
        }

        return null;
      })}
    </ul>
  );
};

const MenuCore = ({
  portal,
  openPortal,
  closePortal,
  isOpen,
  data,
}: PortalFunctionParams & {
  data: MenuItem[];
}) => {
  React.useEffect(
    function preventBodyScroll() {
      const menuClosedClassName = document.body.className
        .replace("overflow-hidden", "")
        .trim();

      if (isOpen) {
        document.body.className = `${menuClosedClassName} overflow-hidden`;
      } else {
        document.body.className = menuClosedClassName;
      }
    },
    [isOpen]
  );

  return (
    <>
      <button onClick={openPortal} className="absolute p-4 pb-0">
        <MenuIcon aria-label="Open main menu" />
      </button>
      <AnimatePresence>
        {portal(
          <motion.nav
            initial={{ opacity: 0, x: "-200vw" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "-200vw" }}
            transition={{ duration: 0.3, type: "tween" }}
            id="main-menu"
            className={classnames(
              "flex",
              "bg-transparent",
              "fixed",
              "h-screen",
              "top-0 overflow-hidden z-20 min-w-screen"
            )}
            aria-label="Main menu"
          >
            <MenuItems
              className="flex-auto flex-grow-0 max-w-lg"
              closeMenu={closePortal}
              data={data}
            />

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, type: "tween" }}
              className={classnames(
                styles.backdrop,
                "flex-shrink-0 flex-grow min-w-24 bg-modalBackdrop"
              )}
              onClick={closePortal}
            ></motion.div>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export const HamburgerMenu = ({ className, data }: MenuProps) => {
  return (
    <div className={className}>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {params => <MenuCore {...params} data={data} />}
      </PortalWithState>
    </div>
  );
};
