import classnames from "classnames";
import React from "react";
import { PortalFunctionParams, PortalWithState } from "react-portal";

export interface Props {
  trigger: React.ReactElement;
  children: React.ReactElement;
}

const DropdownCore = ({
  trigger: triggerElement,
  openPortal,
  closePortal: closePortalCore,
  portal,
  children,
}: PortalFunctionParams & Props) => {
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const rectangle = triggerRef.current?.getBoundingClientRect();

  const [scrollY, setScrollY] = React.useState<number>(0);

  React.useEffect(() => {
    const onScroll = (e: Event) => {
      setScrollY(window.pageYOffset);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [setScrollY]);

  const [openCount, setOpenCount] = React.useState(0);

  const incremementOpenCount = React.useCallback(
    () => setOpenCount(c => c + 1),
    [setOpenCount]
  );
  const decrementOpenCount = React.useCallback(() => setOpenCount(c => c - 1), [
    setOpenCount,
  ]);

  const closePortal = React.useCallback(() => {
    closePortalCore();
    setOpenCount(0);
  }, [closePortalCore, setOpenCount]);

  React.useEffect(() => {
    if (openCount <= 0) {
      closePortal();
    } else {
      openPortal();
    }
  }, [openCount, closePortal, openPortal]);

  const trigger = React.cloneElement(triggerElement, {
    onClick: openPortal,
    onMouseEnter: incremementOpenCount,
    onMouseLeave: decrementOpenCount,
    ref: triggerRef,
  });
  const dropdown = rectangle ? (
    <div
      className="absolute z-10 "
      style={{ left: rectangle?.left, top: rectangle?.bottom + scrollY }}
      onMouseEnter={incremementOpenCount}
      onMouseLeave={decrementOpenCount}
      onClick={closePortal}
    >
      {children}
    </div>
  ) : (
    <div />
  );

  return (
    <>
      {trigger}
      {portal(dropdown)}
    </>
  );
};

export const Dropdown = ({ trigger, children }: Props) => {
  return (
    <div className={classnames("relative h-full")}>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {params => (
          <DropdownCore {...params} children={children} trigger={trigger} />
        )}
      </PortalWithState>
    </div>
  );
};
