import classnames from "classnames";
import React from "react";

export interface Props {
  className?: string;
}

export const Ribbon = ({ className }: Props) => {
  return (
    <div
      className={classnames(
        `sm:hidden lg:block box-border min-w-32 w-32 border-r-40 border-ribbon float-left`,
        className
      )}
    >
      &nbsp;
    </div>
  );
};
